html,
body,
#root,
.App {
  background-color: #5773741a;
  height: 100%;
  margin: 0px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
  color: inherit;
}
.MuiDataGrid-row:nth-child(odd) {
  background-color: #009da010;
}
.MuiDataGrid-row:nth-child(even) {
  background-color: #e0e0e010;
}
.css-1pans1z-MuiDataGrid-virtualScroller {
  overflow-x: hidden !important;
}
